import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'

const CustomDataTable = ({
  columns,
  data,
  loading = false,
  pagination = true,
  searchable = false,
  exportable = false,
  exportFilename = 'data_export.csv',
  expandableRows = false,
  expandableRowsComponent,
}) => {
  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredData = data.filter((item) => {
    if (!searchable || searchQuery.trim() === '') return true
    return columns.some((col) => {
      if (col.searchable) {
        const value = col.selector(item)
        return value
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      }
      return false
    })
  })

  const exportCSV = () => {
    const formattedData = filteredData.map((row) => {
      const formattedRow = {}
      columns.forEach(({ name, selector, exportFormat, exportable }) => {
        if (false === exportable) return
        if (exportFormat) {
          formattedRow[name] = exportFormat(row)
          return
        }
        formattedRow[name] = selector ? selector(row) : ''
      })
      return formattedRow
    })

    const csv = Papa.unparse(formattedData, { header: true })
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', exportFilename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      {data.length > 0 && (searchable || exportable) && (
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          {searchable ? (
            <div className='col-md-3'>
              <input
                type='search'
                className='form-control'
                placeholder={t('SEARCH')}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          ) : (
            <div></div>
          )}

          {filteredData.length > 0 && exportable && (
            <button className='btn btn-primary float-end' onClick={exportCSV}>
              {t('EXPORT_AS_CSV')}
            </button>
          )}
        </div>
      )}

      <DataTable
        columns={columns}
        data={filteredData}
        progressPending={loading}
        pagination={pagination}
        noDataComponent={t('DATA_TABLE_EMPTY')}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
      />
    </div>
  )
}

export default CustomDataTable
