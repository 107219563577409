import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { hideLoading, showLoading } from '../../../slices/loading-slice';
import { DaysList, MonthList, YearDobList, YearExpirationList } from '../../../utils/helper';
import { setSEND_MONEY_OBJECT } from '../../../slices/send-money-ria-slice';
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import cloneDeep from 'lodash/cloneDeep';
import { useImmer } from 'use-immer';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import storage from '../../firebaseConfig';
import { uuidv4 } from '@firebase/util';
import { showToast } from '../../../slices/toast-slice';


function BnbSender() {

    const globalAuthUser = useSelector(state => state.auth.globalAuthUser);
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const [sendMoneyObject, setSendMoneyObject] = useImmer(SEND_MONEY_OBJECT);
    const [frontFile, setFrontFile] = useState();
    const [backFile, setBackFile] = useState();
    const [frontPreview, setFrontPreview] = useState()
    const [backPreview, setBackPreview] = useState()
    const DAYS = DaysList();
    const MONTHS = MonthList();
    const YEARS = YearDobList();
    const YEARS_EXPIRY = YearExpirationList();
    const authUser = useAuthUser()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    console.log("globalAuthUser", globalAuthUser)

    var schemaObject = {};

    if(sendMoneyObject?.senderTab?.showMode == 'add') 
    {
        schemaObject['firstName'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['lastName'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['address'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['city'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['phoneNumber'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['emailAddress'] = yup.string()
        schemaObject['gender'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['occupation'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthDay'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdType'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdNumber'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdExpiryDay'] = yup.string().required(t("FIELD_REQUIRED"))
    } 
    else if(sendMoneyObject?.senderTab?.showMode == 'edit') 
    {
        schemaObject['address'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['city'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['phoneNumber'] = yup.string()
        schemaObject['emailAddress'] = yup.string()
        schemaObject['gender'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['occupation'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthYear'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthMonth'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['birthDay'] = yup.string().required(t("FIELD_REQUIRED"))
        schemaObject['photoIdType'] = yup.string()
        schemaObject['photoIdNumber'] = yup.string()
        schemaObject['photoIdExpiryYear'] = yup.string()
        schemaObject['photoIdExpiryMonth'] = yup.string()
        schemaObject['photoIdExpiryDay'] = yup.string()
    } 

    const schema = yup.object().shape(
        schemaObject
    )

    var { register, handleSubmit, formState: { errors }, trigger, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange"
    });

    useEffect(() => {
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
    }, [sendMoneyObject]);

    useEffect(() => {
        dispatch(showLoading());
        axios({
            method: "get",
            url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getSendMoneyEnums',
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authUser().tokenType + " " + authUser().token,
            }
        })
        .then((res) => {
            dispatch(hideLoading());
            if(res.data.status == 1) {
                setSendMoneyObject(prev => {
                    prev.senderTab.enumTable = res.data.data
                })
            }
        })
        .catch((error) => {
            dispatch(hideLoading());
            console.log(error)
        })
    }, [])

    

    const onSearch = () => {
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/searchCustomer',
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authUser().tokenType + " " + authUser().token,
            },
            data: {
                agent_country_id: globalAuthUser?.location?.agent_country.id,
                searchParam: document.getElementById("searchText").value
            },
        })
        .then((res) => {
            dispatch(hideLoading());
            console.log(res)
            if(res.data.status == 1) {
                setSendMoneyObject((prev) => {
                    prev.senderTab.sender = res.data.data
                    prev.senderTab.showMode = "view"
                    prev.recvViewMode = ""
                })
            } 
            else {
                dispatch( showToast(["danger", "ERROR", res.data.message]))
            }
        })
        .catch((error) => {
            dispatch(hideLoading());
            console.log(error)
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
        })
    }

    const onSearchTextChange = (event) => {
        
    }

    const getInitials = (consumer) => {
        var name = consumer.firstName.charAt(0);
        name += consumer.firstLastName.charAt(0);
        return name
    }

    const getSenderName = (consumer) => {
        var name = '';
        if (consumer.firstName != null) {
            name += consumer.firstName;
        }
        if (consumer.middleName != null) {
            name += ' ' + consumer.middleName;
        }
        if (consumer.firstLastName != null) {
            name += ' ' + consumer.firstLastName;
        }
        if (consumer.secondLastName != null) {
            name += ' ' + consumer.secondLastName;
        }
        return name;
    }

    const onAddsender = () => {
        reset()
        setFrontFile(null)
        setFrontPreview(null)
        setBackFile(null)
        setBackPreview(null)
        setSendMoneyObject((prev) => {
            prev.senderTab.showMode = "add"
        })
    }
    

    const onEditSender = () => {
        reset()
        setSendMoneyObject((prev) => {
            prev.senderTab.showMode = "edit"
        })
    }
 
    const onSaveSender = async (data) => 
    {
        let params = {}
        params.customer_id = sendMoneyObject.senderTab.showMode == 'add' ? undefined : sendMoneyObject.senderTab.sender.id
        params.first_name = sendMoneyObject.senderTab.showMode == 'add' ? data.firstName : undefined
        params.last_name = sendMoneyObject.senderTab.showMode == 'add' ? data.lastName : undefined
        params.address = data.address
        params.city = data.city
        params.country_code = sendMoneyObject.senderTab.showMode == 'add' ? globalAuthUser?.location?.agent_country.isoname : undefined
        params.phone_code = sendMoneyObject.senderTab.showMode == 'add' ? globalAuthUser?.location?.agent_country.phone_code : undefined
        params.phone_number = sendMoneyObject.senderTab.showMode == 'add' ? data.phoneNumber : undefined
        params.email = data.emailAddress
        params.gender = data.gender
        params.occupation = data.occupation
        params.date_of_birth = data.birthYear + '-' + data.birthMonth + '-' + data.birthDay
        params.id_type_id    = sendMoneyObject.senderTab.showMode == 'add' ? data.photoIdType : undefined
        params.id_number = sendMoneyObject.senderTab.showMode == 'add' ? data.photoIdNumber : undefined
        params.expiry_date = sendMoneyObject.senderTab.showMode == 'add' ? data.photoIdExpiryYear + '-' + data.photoIdExpiryMonth + '-' + data.photoIdExpiryDay : undefined
        // params.id_photo_front_url = sendMoneyObject.senderTab.showMode == 'add' ? "https://picsum.photos/200/300" : undefined
        // params.id_photo_back_url = sendMoneyObject.senderTab.showMode == 'add' ? "https://picsum.photos/200/300" : undefined

        if(sendMoneyObject.senderTab.showMode == 'add') {
            if(process.env.REACT_APP_ENVIRONMENT != undefined && process.env.REACT_APP_ENVIRONMENT == "production") {
                console.log('in storage')
                if(frontFile != null) {
                    const storageRef = ref(storage, `/UserKYCDocs/${uuidv4() + '-' + frontFile.name}`);
                    await uploadBytesResumable(storageRef, frontFile);
                    const url = await getDownloadURL(storageRef)
                    console.log(url)
                    params.id_photo_front_url = url
                }
                if(backFile != null) {
                    const storageRef = ref(storage, `/UserKYCDocs/${uuidv4() + '-' + backFile.name}`);
                    await uploadBytesResumable(storageRef, backFile);
                    const url = await getDownloadURL(storageRef)
                    console.log(url)
                    params.id_photo_back_url = url
                }
            } else {
                console.log('not storage')
            }
        }

        console.log("params", params)

        let url = sendMoneyObject.senderTab.showMode == 'add' ? process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveCustomer' : process.env.REACT_APP_BNB_BASE_URL + '/api/portal/updateCustomerDetails'
        dispatch(showLoading());
        axios({
            method: "post",
            url: url,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authUser().tokenType + " " + authUser().token,
            },
            data: params
        })
        .then((res) => {
            dispatch(hideLoading());
            console.log(res)
            if(res.data.status == 1) {
                setSendMoneyObject((prev) => {
                    prev.senderTab.sender = res.data.data
                    prev.senderTab.showMode = "view"
                })
            }
            else {
                dispatch( showToast(["danger", "ERROR", res.data.message]))
            }
        })
        .catch((error) => {
            dispatch(hideLoading());
            console.log(error)
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]))
        })
    }

    function getSenderDateDetails(params) {
        if(sendMoneyObject.senderTab.sender.date_of_birth == undefined) return null
        if(params == 'year') {
            return sendMoneyObject.senderTab.sender.date_of_birth.slice(0, 4)
        } else if(params == 'month') {
            return sendMoneyObject.senderTab.sender.date_of_birth.slice(5, 7)
        } else if(params == 'day') {
            return sendMoneyObject.senderTab.sender.date_of_birth.slice(8, 10)
        }
    }

    function getSenderIdExpiryDateDetails(params) {
        if(sendMoneyObject.senderTab.sender.photo_id_expiry_date == undefined) return null
        if(params == 'year') {
            return sendMoneyObject.senderTab.sender.photo_id_expiry_date.slice(0, 4)
        } else if(params == 'month') {
            return sendMoneyObject.senderTab.sender.photo_id_expiry_date.slice(5, 7)
        } else if(params == 'day') {
            return sendMoneyObject.senderTab.sender.photo_id_expiry_date.slice(8, 10)
        }
    }

    function getSenderIdTypeDetails() {
        if(sendMoneyObject.senderTab.sender.photo_id_type == undefined) {
            console.log("getSenderIdTypeDetails1", sendMoneyObject.senderTab.sender.photo_id_type)
            return null
        } 
        else {
            let objj =  sendMoneyObject.senderTab.enumTable.governmentIdTypes.find(obj => obj.id_type_description == sendMoneyObject.senderTab.sender.photo_id_type)
            console.log("getSenderIdTypeDetails", objj)
            return objj.id
        }
            
    }

    const onNext = () => {
        setSendMoneyObject((prev) => {
            prev.previousStep = 2
            prev.step = 3
            if(prev.recvViewMode != "show") {
                prev.recvViewMode = ""
            }
            prev.purposeOfTransaction = undefined
        })
    }

    const onBack = () => {
        setSendMoneyObject((prev) => {
            prev.previousStep = 2
            prev.step = 1
        })
    }

    const onCancelAdd = () => {
        if(sendMoneyObject.senderTab.sender != undefined) {
            setSendMoneyObject((prev) => {
                prev.senderTab.showMode = "view"
            })
        } else {
            setSendMoneyObject((prev) => {
                prev.senderTab.showMode = ""
            })
        }
        
    }

    const onCancelEdit = () => {
        setSendMoneyObject((prev) => {
            prev.senderTab.showMode = "view"
        })
    }

    const onUsePhoneNoChange = (event) => {
        
    }

    // Handle file upload event and update state
    function handleChangeFront(event) {
        console.log(event.target.files[0])
        setFrontFile(event.target.files[0]);
    }

    // Handle file upload event and update state
    function handleChangeBack(event) {
        setBackFile(event.target.files[0]);
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        console.log(frontFile)
        if (!frontFile) {
            setFrontPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(frontFile)
        console.log(objectUrl)
        setFrontPreview(objectUrl)


        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [frontFile])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        console.log(frontFile)
        if (!backFile) {
            setBackPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(backFile)
        setBackPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [backFile])


    return (
        <>
            <div className='container'>

                <div className='row' style={{ maxWidth: "500px", margin: "auto", marginBottom: '15px' }}>
                    <button className='btn btn-secondary' style={{ maxWidth: '200px', marginLeft: 'auto', marginRight: '0' }} onClick={onAddsender}> {t("ADD_NEW_SENDER")} </button>
                </div>

                <div className='row' style={{ maxWidth: "500px", margin: "auto" }}>
                    <label htmlFor="searchText" className='marg-btm-5'> {t("SEARCH_SENDER_BY_PHONE_NUMBER")} </label>
                    <input type="text" id="searchText" className='form-control marg-btm-5' defaultValue={SEND_MONEY_OBJECT?.searchedPhoneNumber} onChange={onSearchTextChange} onKeyDown={ (evt) => evt.key === "Backspace" || (isNaN(evt.key) && evt.preventDefault()) } />
                    <button className='btn btn-primary' onClick={onSearch}> {t("SEARCH")} </button>
                </div>

                <div>
                    {
                        sendMoneyObject?.senderTab?.showMode == 'view' ?
                            <div>
                                <div>
                                    <hr />
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("FIRSTNAME")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.first_name}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("LASTNAME")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.last_name}</span>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("STATUS")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.status}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("COUNTRY")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.country_name}</span>
                                    </div>
                                </div>
                                <div className="row odds" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("ADDRESS")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.address}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("CITY")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.city}</span>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHONE_NO")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.phone_number}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("EMAIL")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.email}</span>
                                    </div>
                                </div>
                                <div className="row odds" style={{ padding: "10px 1px" }}>

                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("GENDER")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.gender}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("OCCUPATION")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.occupation}</span>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("DATE_OF_BIRTH")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.date_of_birth}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHOTO_ID_TYPE")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.photo_id_type}</span>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHOTO_ID_NUMBER")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.photo_id_number}</span>
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHOTO_ID_EXPIRY_DATE")}</span>
                                        <span className="col-md-8 value">{sendMoneyObject.senderTab.sender?.photo_id_expiry_date}</span>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "10px 1px" }}>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHOTO_ID_FRONT")}</span>
                                        {
                                            sendMoneyObject.senderTab.sender?.photo_id_front != null ?
                                            <a className="col-md-8 value" href={sendMoneyObject.senderTab.sender?.photo_id_front} target="_blank"> {t("CLICK_HERE_TO_VIEW")} </a>
                                            : "!! " + t('UNAVAILABLE') + " !!"
                                        } 
                                    </div>
                                    <div className="col-md-6 row">
                                        <span className="col-md-4 key">{t("PHOTO_ID_BACK")}</span>
                                        {
                                            sendMoneyObject.senderTab.sender?.photo_id_back != null ?
                                            <a className="col-md-8 value" href={sendMoneyObject.senderTab.sender?.photo_id_back} target="_blank"> {t("CLICK_HERE_TO_VIEW")} </a>
                                            : "!! " + t('UNAVAILABLE') + " !!"
                                        } 
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                </div>
                                <div style={{ textAlign: "right", marginTop: "15px" }}>
                                    <button type='button' className="btn btn-primary" onClick={onEditSender}> {t("EDIT")}  </button> &nbsp;
                                </div>
                            </div>
                            : sendMoneyObject?.senderTab?.showMode == 'edit' ?
                                <div>
                                    <form onSubmit={handleSubmit(onSaveSender)}>
                                        <div>
                                            <hr />
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'bold' }}>{sendMoneyObject?.senderTab?.sender?.first_name + ' ' + sendMoneyObject?.senderTab?.sender?.last_name}</span>
                                        </div>
                                        <div>
                                            <hr />
                                        </div>
                                        <div className="row marg-btm">
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="address">{t("ADDRESS")}</label> <span style={{ color: "red" }}>*</span>
                                                <input type="text" id="address" className='form-control' defaultValue={sendMoneyObject?.senderTab?.sender?.address} {...register("address")} />
                                                <p className="input-error-msg"> {errors.address?.message} </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="city">{t("CITY")}</label> <span style={{ color: "red" }}>*</span>
                                                <input type="text" id="city" className='form-control' defaultValue={sendMoneyObject?.senderTab?.sender?.city} {...register("city")} />
                                                <p className="input-error-msg"> {errors.city?.message} </p>
                                            </div>
                                        </div>
                                        <div className="row marg-btm">
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="emailAddress">{t("Email")}</label>
                                                <input type="text" id="emailAddress" className='form-control' defaultValue={sendMoneyObject?.senderTab?.sender?.email} {...register("emailAddress")} readOnly/>
                                                <p className="input-error-msg"> {errors.emailAddress?.message} </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="gender">{t("GENDER")}</label> <span style={{ color: "red" }}>*</span>
                                                <select style={{ maxWidth: "100%" }} className="form-select" name="gender" id="gender" defaultValue={sendMoneyObject?.senderTab?.sender?.gender} {...register("gender")} >
                                                    <option value="">{t("SELECT")}</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <p className="input-error-msg"> {errors.gender?.message} </p>
                                            </div>
                                        </div>
                                        <div className="row marg-btm">
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="occupation">{t("OCCUPATION")}</label> <span style={{ color: "red" }}>*</span>
                                                <select style={{ maxWidth: "100%" }} className="form-select" defaultValue={sendMoneyObject?.senderTab?.sender?.occupation} name="occupation" id="occupation" {...register("occupation")} >
                                                    <option value="">{t("SELECT")}</option>
                                                    {
                                                        Object.keys(sendMoneyObject?.senderTab?.enumTable?.occupations)?.map((occupation, key) => (
                                                            <option value={occupation} key={key}>{sendMoneyObject?.senderTab?.enumTable?.occupations[occupation]}</option>
                                                        ))
                                                    }
                                                </select>
                                                <p className="input-error-msg"> {errors.occupation?.message} </p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="col-form-label" htmlFor="dateOfBirth">{t("DATE_OF_BIRTH")}</label> <span style={{ color: "red" }}>*</span>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <select style={{ maxWidth: "100%" }} className="form-select" name="birthDay" id="birthDay" defaultValue={getSenderDateDetails('day')} {...register("birthDay")} >
                                                            <option value="">{t("Day")}</option>
                                                            {DAYS?.map((day, index) => (
                                                                <option key={index} value={day.value}>
                                                                    {day.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <p className="input-error-msg"> {errors.birthDay?.message} </p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <select style={{ maxWidth: "100%" }} className="form-select" name="birthMonth" id="birthMonth" defaultValue={getSenderDateDetails('month')} {...register("birthMonth")} >
                                                            <option value="">{t("Month")}</option>
                                                            {MONTHS?.map((month, index) => (
                                                                <option key={index} value={month.value}>
                                                                    {month.labelEn}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <p className="input-error-msg"> {errors.birthMonth?.message} </p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <select style={{ maxWidth: "100%" }} className="form-select" name="birthYear" id="birthYear" defaultValue={getSenderDateDetails('year')} {...register("birthYear")} >
                                                            <option value="">{t("Year")}</option>
                                                            {YEARS?.map((year, index) => (
                                                                <option key={index} value={year.value}>
                                                                    {year.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <p className="input-error-msg"> {errors.birthYear?.message} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="photoIdType">{t("photoIdType")}</label>
                                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdType" id="photoIdType" defaultValue={getSenderIdTypeDetails()} {...register("photoIdType")} key={getSenderIdTypeDetails()} >
                                                <option value="">{t("SELECT")}</option>
                                                {sendMoneyObject?.senderTab?.enumTable?.governmentIdTypes?.map((type, index) => (
                                                    <option value={type.id} key={index}>{type.id_type_description}</option>
                                                ))}
                                            </select>
                                            <p className="input-error-msg"> {errors.photoIdType?.message} </p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="photoIdNumber">{t("photoIdNumber")}</label> <span style={{ color: "red" }}>*</span>
                                            <input type="text" id="photoIdNumber" name="photoIdNumber" className='form-control' defaultValue={sendMoneyObject?.senderTab?.sender?.photo_id_number} {...register("photoIdNumber")} />
                                            <p className="input-error-msg"> {errors.photoIdNumber?.message} </p>
                                        </div>
                                    </div>
                                    <div className="row marg-btm">
                                        <div className="col-md-6">
                                            <label className="col-form-label" htmlFor="photoIdExpiryDay">{t("PHOTO_ID_EXPIRATION_DATE")}</label> <span style={{ color: "red" }}>*</span>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryDay" id="photoIdExpiryDay" defaultValue={getSenderIdExpiryDateDetails('day')} {...register("photoIdExpiryDay")} >
                                                        <option value="">{t("Day")}</option>
                                                        {DAYS?.map((day, index) => (
                                                            <option key={index} value={day.value}>
                                                                {day.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.photoIdExpiryDay?.message} </p>
                                                </div>
                                                <div className="col-md-5">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryMonth" id="photoIdExpiryMonth" defaultValue={getSenderIdExpiryDateDetails('month')} {...register("photoIdExpiryMonth")} >
                                                        <option value="">{t("Year")}</option>
                                                        {MONTHS?.map((month, index) => (
                                                            <option key={index} value={month.value}>
                                                                {month.labelEn}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.photoIdExpiryMonth?.message} </p>
                                                </div>
                                                <div className="col-md-4">
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryYear" id="photoIdExpiryYear" defaultValue={getSenderIdExpiryDateDetails('year')} {...register("photoIdExpiryYear")} >
                                                        <option value="">{t("Year")}</option>
                                                        {YEARS_EXPIRY?.map((year, index) => (
                                                            <option key={index} value={year.value}>
                                                                {year.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.photoIdExpiryYear?.message} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div>
                                            <hr />
                                        </div>
                                        <div style={{ textAlign: "right", marginTop: "15px" }}>
                                            <button onClick={onCancelEdit} className="btn btn-light"> {t("CANCEL")}  </button> &nbsp;
                                            <button type='submit' className="btn btn-primary"> {t("SAVE")}  </button> &nbsp;
                                        </div>
                                    </form>
                                </div>
                                : sendMoneyObject?.senderTab?.showMode == 'add' &&
                                    <div>
                                        <form onSubmit={handleSubmit(onSaveSender)}>
                                            <div>
                                                <hr />
                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="firstName">{t("firstName")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="firstName" className='form-control' {...register("firstName")} />
                                                    <p className="input-error-msg"> {errors.firstName?.message} </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="lastName">{t("lastName")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="lastName" className='form-control' {...register("lastName")} />
                                                    <p className="input-error-msg"> {errors.lastName?.message} </p>
                                                </div>
                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="address">{t("ADDRESS")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="address" className='form-control' {...register("address")} />
                                                    <p className="input-error-msg"> {errors.address?.message} </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="city">{t("CITY")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="city" className='form-control' {...register("city")} />
                                                    <p className="input-error-msg"> {errors.city?.message} </p>
                                                </div>
                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="phoneNumber">{t("PHONE_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                                    <div className="input-group col-8" style={{ width: "100%" }}>
                                                        <span className="input-group-text" id="basic-addon1"> {globalAuthUser?.location?.agent_country.phone_code} </span>
                                                        <input type="text" id="phoneNumber" className='form-control' aria-describedby="basic-addon1" {...register("phoneNumber")} />
                                                    </div>
                                                    <p className="input-error-msg"> {errors.phoneNumber?.message} </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="emailAddress">{t("Email")}</label>
                                                    <input type="text" id="emailAddress" className='form-control' {...register("emailAddress")} />
                                                    <p className="input-error-msg"> {errors.emailAddress?.message} </p>
                                                </div>

                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="gender">{t("GENDER")}</label> <span style={{ color: "red" }}>*</span>
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="gender" id="gender" {...register("gender")} >
                                                        <option value="">{t("SELECT")}</option>
                                                        <option value="Male">{t("MALE")}</option>
                                                        <option value="Female">{t("FEMALE")}</option>
                                                    </select>
                                                    <p className="input-error-msg"> {errors.gender?.message} </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="occupation">{t("OCCUPATION")}</label> <span style={{ color: "red" }}>*</span>
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="occupation" id="occupation" {...register("occupation")} >
                                                        <option value="">{t("SELECT")}</option>
                                                        {
                                                            Object.keys(sendMoneyObject?.senderTab?.enumTable?.occupations)?.map((occupation, key) => (
                                                                <option value={occupation} key={key}>{sendMoneyObject?.senderTab?.enumTable?.occupations[occupation]}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <p className="input-error-msg"> {errors.occupation?.message} </p>
                                                </div>

                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="dateOfBirth">{t("DATE_OF_BIRTH")}</label> <span style={{ color: "red" }}>*</span>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="birthDay" id="birthDay" {...register("birthDay")} >
                                                                <option value="">{t("Day")}</option>
                                                                {DAYS?.map((day, index) => (
                                                                    <option key={index} value={day.value}>
                                                                        {day.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.birthDay?.message} </p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="birthMonth" id="birthMonth" {...register("birthMonth")} >
                                                                <option value="">{t("Month")}</option>
                                                                {MONTHS?.map((month, index) => (
                                                                    <option key={index} value={month.value}>
                                                                        {month.labelEn}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.birthMonth?.message} </p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="birthYear" id="birthYear" {...register("birthYear")} >
                                                                <option value="">{t("Year")}</option>
                                                                {YEARS?.map((year, index) => (
                                                                    <option key={index} value={year.value}>
                                                                        {year.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.birthYear?.message} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="photoIdType">{t("PHOTO_ID_TYPE")}</label>
                                                    <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdType" id="photoIdType" {...register("photoIdType")} >
                                                        <option value="">{t("SELECT")}</option>
                                                        {sendMoneyObject?.senderTab?.enumTable?.governmentIdTypes?.map((type, index) => (
                                                            <option value={type.id} key={index}>{type.id_type_description}</option>
                                                        ))}
                                                    </select>
                                                    <p className="input-error-msg"> {errors.photoIdType?.message} </p>
                                                </div>
                                            </div>
                                            <div className="row marg-btm">
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="photoIdNumber">{t("PHOTO_ID_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                                    <input type="text" id="photoIdNumber" name="photoIdNumber" className='form-control' {...register("photoIdNumber")} />
                                                    <p className="input-error-msg"> {errors.photoIdNumber?.message} </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="photoIdExpiryDay">{t("PHOTO_ID_EXPIRY_DATE")}</label> <span style={{ color: "red" }}>*</span>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryDay" id="photoIdExpiryDay" {...register("photoIdExpiryDay")} >
                                                                <option value="">{t("Day")}</option>
                                                                {DAYS?.map((day, index) => (
                                                                    <option key={index} value={day.value}>
                                                                        {day.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.photoIdExpiryDay?.message} </p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryMonth" id="photoIdExpiryMonth" {...register("photoIdExpiryMonth")} >
                                                                <option value="">{t("Month")}</option>
                                                                {MONTHS?.map((month, index) => (
                                                                    <option key={index} value={month.value}>
                                                                        {month.labelEn}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.photoIdExpiryMonth?.message} </p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select style={{ maxWidth: "100%" }} className="form-select" name="photoIdExpiryYear" id="photoIdExpiryYear" {...register("photoIdExpiryYear")} >
                                                                <option value="">{t("Year")}</option>
                                                                {YEARS_EXPIRY?.map((year, index) => (
                                                                    <option key={index} value={year.value}>
                                                                        {year.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <p className="input-error-msg"> {errors.photoIdExpiryYear?.message} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="photoIdFrontImage">{t("PHOTO_ID_FRONT")}</label>
                                                    <input className='form-control' id="photoIdFrontImage" name="photoIdFrontImage" type="file" onChange={handleChangeFront} accept="/image/*" />
                                                    <img style={{ marginTop: '10px' }} width="200px" alt="" src={frontPreview} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label" htmlFor="photoIdBackImage">{t("PHOTO_ID_BACK")}</label> 
                                                    <input className='form-control' id="photoIdBackImage" name="photoIdBackImage" type="file" onChange={handleChangeBack} accept="/image/*" />
                                                    <img style={{ marginTop: '10px' }} width="200px" alt="" src={backPreview} />
                                                </div>
                                            </div>
                                            <div>
                                                <hr />
                                            </div>
                                            <div style={{ textAlign: "right", marginTop: "15px" }}>
                                                <button onClick={onCancelAdd} className="btn btn-light"> {t("CANCEL")}  </button> &nbsp;
                                                <button type='submit' className="btn btn-primary"> {t("SAVE")}  </button> &nbsp;
                                            </div>
                                        </form>
                                    </div>

                    }
                </div>

                <div className='row' style={{ padding: "15px" }}>
                    <hr style={{ margin: '0' }} />
                </div>

                <div className='row' style={{ padding: "2px" }}>
                    
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button className='btn btn-secondary' onClick={onBack}>{t("BACK")}</button>
                        {
                        sendMoneyObject?.senderTab.sender && sendMoneyObject?.senderTab.showMode == 'view' &&
                        <button className="btn btn-primary" disabled={sendMoneyObject?.feesTab?.feesResult?.success == undefined || sendMoneyObject?.feesTab?.feesResult?.success == false} onClick={onNext}>Next</button>
                    }
                    </div>
                </div>

                <br /><br />

            </div>
        </>
    );

    function useStateCallback(initialState) {
        const [state, setState] = useImmer(initialState);
        const cbRef = useRef(null);

        const setStateCallback = useCallback((state, cb) => {
            cbRef.current = cb;
            setState(state);
        }, []);

        useEffect(() => {
            if (cbRef.current) {
                cbRef.current(state);
                cbRef.current = null;
            }
        }, [state]);

        return [state, setStateCallback];
    }

}

export default BnbSender