import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthUser } from 'react-auth-kit'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import { formatAmount } from '../../utils/helper'
import CustomDataTable from '../CustomDataTable'

const PaidTxnIndex = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser)

  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [duration, setDuration] = useState('1')
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [agents, setAgents] = useState([])
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [subLocations, setSubLocations] = useState([])
  const [selectedSubLocation, setSelectedSubLocation] = useState(null)
  const [transactions, setTransactions] = useState([])
  const [reconciliationStatus, setReconciliationStatus] = useState(null)
  const [submitPaid, setSubmitPaid] = useState(false)
  useEffect(() => {
    setSubmitPaid(
      globalAuthUser.location.agent_country.enable_submitPaid === '1'
    )

    fetchAgents()
    fetchSubLocations()
  }, [])

  useEffect(() => {
    fetchData()
  }, [
    selectedCompany,
    duration,
    selectedAgent,
    selectedSubLocation,
    reconciliationStatus,
    fromDate,
    toDate,
  ])

  const fetchData = async () => {
    if (duration === '6' && (!fromDate || !toDate)) return

    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/partner/receiveTransactions`,
        {
          agent_location_id: selectedSubLocation ?? globalAuthUser?.location.id,
          agent_company_id: selectedCompany,
          duration,
          from_date: duration === '6' ? fromDate : undefined,
          to_date: duration === '6' ? toDate : undefined,
          agent_location_user_id: selectedAgent,
          is_reconciled: reconciliationStatus,
          paginate: false,
        },
        {
          headers: {
            Authorization: `${authUser().tokenType} ${authUser().token}`,
          },
        }
      )
      setTransactions(response.data.data.transactions)
      setCompanies(response.data.data.agentCompanies)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchAgents = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/agentLocations/${globalAuthUser?.location.id}/agents`,
        {
          headers: {
            Authorization: `${authUser().tokenType} ${authUser().token}`,
          },
          params: {
            onlyTeller: true,
          },
        }
      )
      setAgents(response.data)
    } catch (error) {
      console.error('Error fetching agents:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSubLocations = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/agentLocations/${globalAuthUser?.location.id}/subLocations`,
        {
          headers: {
            Authorization: `${authUser().tokenType} ${authUser().token}`,
          },
        }
      )
      setSubLocations(response.data)
    } catch (error) {
      console.error('Error fetching sub-locations:', error)
    } finally {
      setLoading(false)
    }
  }

  const getCurrentDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }

  const handleFromDateChange = (e) => {
    const selectedDate = e.target.value
    setFromDate(selectedDate)

    const autoToDateObject = new Date(selectedDate)
    autoToDateObject.setDate(autoToDateObject.getDate() + 30)
    setToDate(autoToDateObject.toISOString().split('T')[0])
  }

  const durations = [
    { value: '1', label: t('TODAY') },
    { value: '2', label: t('THIS_WEEK') },
    { value: '3', label: t('THIS_MONTH') },
    { value: '4', label: t('PREVIOUS_WEEK') },
    { value: '5', label: t('PREVIOUS_MONTH') },
    { value: '6', label: t('CUSTOM_DATE') },
  ]

  const columns = [
    {
      name: '#',
      cell: (_, index) => index + 1,
      width: '70px',
      sortable: true,
      exportable: false,
    },
    {
      name: 'Reference',
      selector: (row) => row.pickup_code,
      cell: (row) => (
        <a href={`/paid-transaction/${row.id}`}>{row.pickup_code}</a>
      ),
      sortable: true,
      searchable: true,
    },
    {
      name: 'Company',
      selector: (row) => row.company,
      width: '170px',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.receiving_amount,
      cell: (row) =>
        `${formatAmount(row.receiving_amount)} ${row.receiving_currency}`,
      width: '150px',
      sortable: true,
      exportFormat: (row) =>
        `${formatAmount(row.receiving_amount)} ${row.receiving_currency}`,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      width: '100px',
      cell: (row) =>
        row.status === 'paid' ? (
          <span class='badge text-bg-success'>{t(row.status)}</span>
        ) : (
          <span class='badge text-bg-secondary'>{t(row.status)}</span>
        ),
      sortable: true,
    },
    {
      name: 'Paid At',
      selector: (row) => row.paid_at,
      sortable: true,
    },
    {
      name: 'Paid By',
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      searchable: true,
    },
    {
      name: 'Notes',
      selector: (row) => row.notes,
    },
  ]

  return (
    <div style={{ padding: '20px' }}>
      <div className='card'>
        {submitPaid && (
          <div className='card-header d-flex justify-content-between align-items-center'>
            <h5>{t('PAID_TRANSACTIONS')}</h5>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => navigate('/paid-transaction/create')}
            >
              {t('SUBMIT_NEW_PAID_TRANSACTION')}
            </button>
          </div>
        )}
        <div className='card-body'>
          <form>
            <div className='form-group row'>
              <div className='col-md-3 mb-3'>
                <label>{t('COMPANY')}</label>
                <Select
                  isClearable
                  options={companies.map((company) => ({
                    value: company.company_id,
                    label: company.company_code,
                  }))}
                  onChange={(e) => setSelectedCompany(e?.value || null)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <label>{t('PAID_BY')}</label>
                <Select
                  isClearable
                  options={agents.map((agent) => ({
                    value: agent.id,
                    label: `${agent.first_name} ${agent.last_name}`,
                  }))}
                  onChange={(e) => setSelectedAgent(e?.value || null)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <label>{t('SUB_LOCATION')}</label>
                <Select
                  isClearable
                  options={subLocations.map((subLocation) => ({
                    value: subLocation.id,
                    label: subLocation.name,
                  }))}
                  onChange={(e) => setSelectedSubLocation(e?.value || null)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <label>{t('RECONCILIATION_STATUS')}</label>
                <Select
                  isClearable
                  options={[{ value: '1', label: t('RECONCILED') }]}
                  onChange={(e) => setReconciliationStatus(e?.value || null)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <label>{t('DURATION')}</label>
                <Select
                  options={durations}
                  defaultValue={durations[0]}
                  onChange={(selectedOption) =>
                    setDuration(selectedOption.value)
                  }
                />
              </div>
              {duration === '6' && (
                <>
                  <div className='col-md-3 mb-3'>
                    <label>{t('FROM_DATE')}</label>
                    <input
                      type='date'
                      className='form-control'
                      value={fromDate || ''}
                      onChange={handleFromDateChange}
                      max={getCurrentDate()}
                    />
                  </div>
                  <div className='col-md-3 mb-3'>
                    <label>{t('TO_DATE')}</label>
                    <input
                      type='date'
                      className='form-control'
                      value={toDate || ''}
                      disabled
                    />
                  </div>
                </>
              )}
            </div>
          </form>

          <CustomDataTable
            columns={columns}
            data={transactions}
            loading={loading}
            exportFilename={`paid_transactions_${new Date()
              .toISOString()
              .replace(/[-T:.Z]/g, '')
              .slice(0, 14)}.csv`}
            exportable
            searchable
          />
        </div>
      </div>
    </div>
  )
}

export default PaidTxnIndex
